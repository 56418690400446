<template>
  <div>
    <div class="pageHeader" style="overflow: hidden">
      <div style="float: left">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="handle_clickaddjob()"
          >新建职位</el-button
        >
        <el-button type="primary" icon="el-icon-sold-out" @click="handle_export()">导出</el-button>
        <el-button type="success" icon="el-icon-refresh" @click="initPageData()"
          >刷新</el-button
        >
      </div>
      <div style="float: right">
        <el-input
          placeholder="职位详细或企业名称"
          v-model="keyword"
          class="input-with-select"
          style="width: 250px"
        ></el-input
        >&nbsp;
        <el-select
          v-model="status"
          placeholder="请职位状态"
          style="margin-right: 5px"
        >
          <el-option
            v-for="item in statuses"
            :key="item"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>

        <el-button type="primary" @click="handle_search()" icon="el-icon-search"
          >搜索</el-button
        >
      </div>
    </div>

    <div>
      <el-table
        :data="page.list"
        v-loading="showLoading"
        element-loading-text="拼命加载中"
        border
        width="width:100%; height:50%"
      >
        <el-table-column type="index" label="顺序" width="50"></el-table-column>
        <el-table-column prop="title" label="职位名称"></el-table-column>
        <el-table-column label="职位分类" width="150">
          <template #default="scope">
            {{ scope.row?.category }} {{ scope.row?.subcategory }}
          </template>
        </el-table-column>
        <el-table-column
          prop="employerUser.name"
          label="雇主名称"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="employerUser.mobile"
          label="联系方式"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="employer.companyname"
          label="企业名称"
          with="150"
        >
        </el-table-column>
        <el-table-column
          prop="createtime"
          label="加入时间"
          width="150"
        >
          <template #default="scope">
            {{computeTime(scope.row?.createtime)}}
          </template>       
        </el-table-column>
        <el-table-column
          prop="publishtime"
          label="发布时间"
          width="150"
        >
          <template #default="scope">
            {{computeTime(scope.row?.publishtime)}}
          </template>       
        </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
          width="80"
          :formatter="formatStatus"
        ></el-table-column>

        <el-table-column label="操作" conter width="150">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="handle_clickedit(scope.row)"
              v-show="scope.row.status == 0"
              >编辑
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="handle_clicktoverify(scope.row)"
              v-show="scope.row.status == 0"
              >审核
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="handle_clickcancelverify(scope.row)"
              v-show="scope.row.status == 1"
              >取消审核
            </el-button>
             <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="handle_clicktopublish(scope.row)"
              v-show="scope.row.status == 1"
              >发布
            </el-button>
             <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="handle_clicktooffline(scope.row)"
              v-show="scope.row.status == 2"
              >下线
            </el-button>
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              @click="handle_clicktonew(scope.row)"
              v-show="scope.row.status == 3"
              >下线恢复
            </el-button>

            <el-button
              type="text"
              icon="el-icon-delete"
              @click="handle_clickdelete(scope.row)"
              v-show="scope.row.status == 0||scope.row.status==3"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="devlogChange"
        :current-page="page.pageNum"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import JOB from "../../api/lianying/api_job";
import moment from 'moment';

export default {
  name: "joblist", // 工作列表
  props: {},
  data() {
    return {
      keyword: "",
      status: -1,
      showLoading: false,
      page: { list: [], pageNum: 1, pageSize: 10, total: 0 },
      statuses: [
        { name: "全部", value: -1 },
        { name: "新建", value: 0 },
        { name: "审核", value: 1 },
        { name: "发布", value: 2 },
        { name: "下线", value: 3 },
        { name: "删除", value: 4 },
      ],
      auth: {},
    };
  },
  // 初始化加载
  mounted() {
    this.init();
  },
  computed: {
    computeTime:function(){
      return function(time){
        if(!time){
          return '';
        }
        return moment(time).format('YYYY-MM-DD hh:mm:ss');

      }
    }
  },
  // 方法定义
  methods: {
    init() {
      this.initPageData();
    },

    // 加载页面数据
    initPageData() {
      this.showLoading = true;
      let currentStatus = undefined;
      if (this.status != -1) {
        currentStatus = this.status;
      }

      JOB.list({
        currentPage: this.page?.pageNum,
        keyword: this.keyword,
        status: currentStatus,
        pageSize: this.page?.pageSize,
      }).then((res) => {
        this.showLoading = false;
        console.log("list res " + JSON.stringify(res));
        if (1 == res.status) {
          var data = res?.data;
          this.page.total = data?.totalNumber;
          this.page.list = data?.data;

          console.log("total " + this.page.total);
        } else {
          this.$message.error(res?.message);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      console.log("page " + pageNum);
      this.page.pageNum = pageNum;
      this.initPageData();
    },
    // handle_add() {
    //   let id = 1;
    //   this.$router.push({
    //     path: "../job/edit",
    //     query: { id: id },
    //   });
    // },
    // addJob() {
    //   console.log("add job");
    // },

    handle_clickedit(row) {
      this.$router.push({
        path: "/job/edit",
        query: {
          id: row?.id,
        },
      });
    },

    // formatType(row, coloumn) {
    //   let type = row?.type;
    //   return type === 0 ? "新客开拓" : type === 1 ? "创建年度验证计划" : "未知";
    // },
    formatStatus(row, column) {
      let status = row?.status;
      if (status == 0) {
        return "新建";
      } else if (status == 1) {
        return "审核";
      } else if (status == 2) {
        return "发布";
      } else if (status == 3) {
        return "下线";
      } else if(status==4){
        return "删除";
      }else{
        return "未知";
      }
    },
    handle_search() {
      this.initPageData();
    },
    handle_clickaddjob() {
      this.$router.push({
        path: "/job/edit",
        query: {},
      });
    },
    handle_clickdelete(row) {
      let name=row.employer.companyname+" "+row.title;
     
      this.$confirm("此操作将删除[" + name + "] 职位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.jobid = row.id;
          params.employerid=row.employerid;
          JOB.delete(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("成功删除");
              if(row.status==3){
                row.status=4;
              }else{
                let idx=this.page.list.findIndex((item)=>{
                  return item.id==row.id;
                })
                if(idx>=0){
                this.page.list.splice(idx,1);
                }
              }
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    //verify
    handle_clicktoverify(row) {
       let name=row.employer.companyname+" "+row.title;
      this.$confirm("此操作将提交审核[" + name + "] 职位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.id = row.id;
          //params.employerid=row.employerid;
          params.status=1;
          JOB.updateStatus(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("操作成功");
              this.page.list.map((item)=>{
                if(item.id==row.id){
                  item.status=1;
                }
              })
              
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    //cancelverify
    handle_clickcancelverify(row) {
       let name=row.employer.companyname+" "+row.title;
      this.$confirm("此操作将取消审核[" + name + "] 职位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.id = row.id;
          //params.employerid=row.employerid;
          params.status=0;
          JOB.updateStatus(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("操作成功");
              this.page.list.map((item)=>{
                if(item.id==row.id){
                  item.status=0;
                }
              })
              
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    //topublish
    handle_clicktopublish(row) {
       let name=row.employer.companyname+" "+row.title;
      this.$confirm("此操作将发布[" + name + "] 职位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.id = row.id;
          //params.employerid=row.employerid;
          //params.status=2;
          JOB.publish(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("操作成功");
              this.page.list.map((item)=>{
                if(item.id==row.id){
                  item.status=2;
                  item.publishtime=Date.now();
                }
              })
              
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    //tooffline
    handle_clicktooffline(row) {
       let name=row.employer.companyname+" "+row.title;
      this.$confirm("此操作将下线[" + name + "] 职位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.id = row.id;
          params.employerid=row.employerid;
          params.status=3;
          JOB.updateStatus(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("操作成功");
              this.page.list.map((item)=>{
                if(item.id==row.id){
                  item.status=3;
                }
              })
              
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    handle_clicktonew(row) {
       let name=row.employer.companyname+" "+row.title;
      this.$confirm("此操作将下线[" + name + "] 职位恢复到新建状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {};
          params.id = row.id;
          //params.employerid=row.employerid;
          params.status=0;
          JOB.updateStatus(params).then((res) => {
            if (res.status == 1) {
              this.$message.success("操作成功");
              this.page.list.map((item)=>{
                if(item.id==row.id){
                  item.status=0;
                }
              })
              
            }else{
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {});
    },
    //设置导出
    handle_export(row) {

      console.log("currentPage "+this.page.pageNum);

      if (!this.page.list || this.page.list.length == 0) {
        this.$message.error("没有数据导出");
        return;
      }
      let params = {
        currentPage: 1,
        pageSize: 1000000000,
        keyword: this.keyword,
      };
      
      JOB.exportBlob(params).then((res) => {
        console.log("res "+JSON.stringify(res));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = window.URL.createObjectURL(new Blob([res]));
        link.setAttribute("download", "职位列表.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$message.success("操作成功！");
      });
    },
  },
};
</script>

<style scoped>
.search {
  margin-bottom: 10px;
}
.search_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.search_buttons input {
  margin-right: 20px;
}
.search_buttons input:last-child {
  margin-right: 0;
}
.pageHeader {
  background-color: white;
  border-bottom: 1px solid #cccccc;
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 5px;
  /*padding: 5px 10px;*/
}

.red {
  color: #ff0000;
}
</style>